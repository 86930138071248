import React from "react";
import useWindowSize from "../useWindowSize";
import { useTranslation } from "react-i18next";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  return (
    <div>
      <div id="contact">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
        >
          {width > 768 && (
            <img
              style={{
                width: width < 992 ? "50%" : "200px",
                objectFit: "contain",
              }}
              src={props.data?.img || ""}
              alt=""
            />
          )}
          <div
            style={{
              width: width < 992 ? (width > 768 ? "50%" : "100%") : "100%",
              display: "flex",
              alignItems: "start",
              minHeight: "200px",
              marginLeft: width > 768 ? "30px" : "0px",
              flexDirection: width > 992 ? "row" : "column",
              gap: "30px",
            }}
          >
            <div style={{ width: `calc(100% ${width > 992 ? "/3 * 2" : ""})` }}>
              <div style={{ fontSize: "40px", fontWeight: "700" }}>
                {t("kontakkami")}
              </div>
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "15px",
                  textAlign: "justify",
                  maxWidth: "100%",
                }}
              >
                {t("kontakkamidesc")}
              </p>
              <div style={{ width: "100%", display: "flex", flexDirection: width > 640 ? "row" : "column", gap: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "column",
                    width: "50%"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <i
                      className={props.data?.icon || ""}
                      style={{ fontSize: "20px" }}
                      alt=""
                    />
                    <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                      Call
                    </span>
                  </div>
                  <a
                    style={{
                      width: "50%",
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                      whiteSpace: "nowrap"
                    }}
                    href="https://api.whatsapp.com/send/?phone=%2B6285817935433&text&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    {t(props.data?.phone) || ""}
                  </a>
                  <p>021-3825-0098</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <i
                      className={props.data?.icon4 || ""}
                      style={{ fontSize: "20px" }}
                      alt=""
                    />
                    <span style={{ fontSize: "15px", marginLeft: "10px" }}>
                      Email
                    </span>
                  </div>
                  <div style={{ width: "50%", whiteSpace: "nowrap" }}>
                    {t(props.data?.email) || ""}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: `calc(100% ${width > 992 ? "/3" : ""})` }}>
              <div style={{ fontSize: "40px", fontWeight: "700" }}>
                {t("temuikami")}
              </div>
              <a
                target="blank"
                href={t(props.data?.facebook) || ""}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                <i
                  className={props.data?.icon1 || ""}
                  style={{ fontSize: "20px" }}
                  alt=""
                />
                <div style={{ marginLeft: "10px" }}>SalmonSegar.Id</div>
              </a>
              <br></br>
              <a
                target="_blank"
                href={t(props.data?.instagram) || ""}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                <i
                  className={props.data?.icon2 || ""}
                  style={{ fontSize: "20px" }}
                  alt=""
                />
                <div style={{ marginLeft: "10px" }}>SalmonSegar.Id</div>
              </a>
              <br></br>
              <div style={{ display: "flex", alignItems: "" }}>
                <i
                  className={props.data?.icon3 || ""}
                  style={{ fontSize: "25px" }}
                  alt=""
                />
                <br></br>
                <p
                  style={{
                    marginBottom: "15px",
                    marginLeft: "10px",
                    textAlign: "justify",
                  }}
                >
                  {t(props.data?.address) || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container">
          <p style={{ textAlign: "center" }}>
            &copy; 2024 PT. Athan Gemilang Perkasa All Rights Reserved
            {/* <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};
