import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProdukModal from "./produkModal";

export const About = (props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState({
    val: false,
    data: null,
  });

  return (
    <>
      <div id="about">
        <div className="container">
          <div className="about-text">
            <h2>{t("produk")}</h2>
            <h3>
              {t("productdesc1")} <b>{t("productdesc2")}</b> {t("productdesc3")}{" "}
              <b>{t("productdesc4")}</b> {t("productdesc5")}
            </h3>
          </div>
          <h3 className="desc-text">{t("productdesctext")}</h3>
          <div className="">
            <div className="" style={{ overflow: "auto" }}>
              <div className="limg">
                {props.data?.produk?.map((value) => {
                  return (
                    <div style={{ width: "100%" }} key={value.title}>
                      <img
                        style={{
                          width: "calc(100%)",
                          borderRadius: "20px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        src={value.img}
                        onClick={() => setShowModal({ val: true, data: value })}
                      />
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <b>{t(value.title)}</b>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {t(value.description)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal.val && (
        <ProdukModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
};
