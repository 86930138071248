import { useTranslation } from "react-i18next";
import { Image } from "./image";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useWindowSize from "../useWindowSize";

export const Gallery = (props) => {

  const { width } = useWindowSize();

  const imgSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: width > 1024 ? 3 : width > 640 ? 2 : 1,
    slidesToScroll: 1,
    // prevArrow: <PrevArrow />,
    // nextArrow: <NextArrow />,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 1500,
    arrows: false,
    customPaging: function(i) {
      return (
        <div className="custom-dot"/>
      );
    },
  };

  const { t } = useTranslation();
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("galeri")}</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            <Slider  {...imgSettings}>
              {props.data
                ? props.data.map((d, i) => (
                    <div
                      key={`${d.title}-${i}`}
                      style={{
                        width: "350px",
                        maxWidth: "100%",
                        flexShrink: 0,
                        height: "200px",
                      }}  
                      className="portfolio-item"
                    >
                      <Image
                        largeImage={d.largeImage}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                : "Loading..."}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
