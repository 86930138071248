import React from "react";
import Logo from "../assets/Logo.svg";
import useWindowSize from "../useWindowSize";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const Navigation = (props) => {
  const { width } = useWindowSize();

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      console.log('Language changed to:', i18n.language); // Should log 'id'
    });
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: width > 768 ? "row" : "column",
          }}
        >
          <div
            className="navbar-header"
            style={width < 768 ? { width: "100%" } : {}}
          >
            <div className={width < 768 && "navbar-between"}>
             {width < 768 && <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    alignItems: "center",
                    fontSize: "17px",
                  }}
                >
                  <span style={{ fontWeight: currentLanguage === "id" ? "700" : "400", cursor: "pointer" }} onClick={() => changeLanguage("id")}>ID</span>
                  <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                    |
                  </span>
                  <span style={{ fontWeight: currentLanguage === "en" ? "700" : "400", cursor: "pointer" }} onClick={() => changeLanguage("en")}>EN</span>
                </div>
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                >
                  {" "}
                  <span className="sr-only">Toggle navigation</span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                </button>
              </div>}
              <a className="navbar-brand page-scroll" href="#page-top">
                <img src={Logo} style={{ height: "46px" }} />
              </a>{" "}
            </div>
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#header" className="page-scroll">
                  {t("beranda")}
                </a>
              </li>
              <li>
                <a href="#features" className="page-scroll">
                  {t("tentangkami")}
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  {t("produk")}
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  {t("kontak")}
                </a>
              </li>
            </ul>
          </div>
          {width > 768 && (
            <div
              style={{
                display: "flex",
                height: "50px",
                alignItems: "center",
                fontSize: "17px",
              }}
            >
              <span style={{ fontWeight: currentLanguage === "id" ? "700" : "400", cursor: "pointer" }} onClick={() => changeLanguage("id")}>ID</span>
              <span style={{ marginLeft: "10px", marginRight: "10px" }}>|</span>
              <span style={{ fontWeight: currentLanguage === "en" ? "700" : "400", cursor: "pointer" }} onClick={() => changeLanguage("en")}>EN</span>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
