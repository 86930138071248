import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "./i18n";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const landingPageData = {
    Header: {
      title: 'Header.title',
      paragraph: 'Header.paragraph'
    },
    About: {
      produk: [
        {
          title: 'About.produk.0.title',
          description: 'About.produk.0.description',
          img: 'img/produk/salmon/1.jpg',
          totalFiles: 8,
          folder: "salmon"
        },
        {
          title: 'About.produk.1.title',
          description: 'About.produk.1.description',
          img: 'img/produk/shrimps/1.jpg',
          totalFiles: 5,
          folder: "shrimps"
        },
        {
          title: 'About.produk.2.title',
          description: 'About.produk.2.description',
          img: 'img/produk/scallop/1.jpg',
          totalFiles: 1,
          folder: "scallop"
        },
        {
          title: 'About.produk.3.title',
          description: 'About.produk.3.description',
          img: 'img/produk/squid/1.jpg',
          totalFiles: 6,
          folder: "squid"
        },
        {
          title: 'About.produk.4.title',
          description: 'About.produk.4.description',
          img: 'img/produk/unagi/1.jpg',
          totalFiles: 1,
          folder: "unagi"
        },
        {
          title: 'About.produk.5.title',
          description: 'About.produk.5.description',
          img: 'img/produk/seafoods/1.jpg',
          totalFiles: 10,
          folder: "seafoods"
        }
      ],
      paragraph: 'About.paragraph',
      Why: [
        'About.Why.0',
        'About.Why.1',
        'About.Why.2',
        'About.Why.3'
      ],
      Why2: [
        'About.Why2.0',
        'About.Why2.1',
        'About.Why2.2',
        'About.Why2.3'
      ]
    },
    Gallery: [
      {
        title: 'Gallery.0.title',
        largeImage: 'img/gallery/Gallery01.jpg',
        smallImage: 'img/gallery/Gallery01.jpg'
      },
      {
        title: 'Gallery.1.title',
        largeImage: 'img/gallery/Gallery02.jpg',
        smallImage: 'img/gallery/Gallery02.jpg'
      },
      {
        title: 'Gallery.2.title',
        largeImage: 'img/gallery/Gallery03.jpeg',
        smallImage: 'img/gallery/Gallery03.jpeg'
      },
      {
        title: 'Gallery.3.title',
        largeImage: 'img/gallery/Gallery04.jpg',
        smallImage: 'img/gallery/Gallery04.jpg'
      },
      {
        title: 'Gallery.4.title',
        largeImage: 'img/gallery/Gallery05.jpeg',
        smallImage: 'img/gallery/Gallery05.jpeg'
      },
      {
        title: 'Gallery.5.title',
        largeImage: 'img/gallery/Gallery06.jpeg',
        smallImage: 'img/gallery/Gallery06.jpeg'
      },
      {
        title: 'Gallery.6.title',
        largeImage: 'img/gallery/Gallery07.jpeg',
        smallImage: 'img/gallery/Gallery07.jpeg'
      },
      {
        title: 'Gallery.7.title',
        largeImage: 'img/gallery/Gallery08.jpeg',
        smallImage: 'img/gallery/Gallery08.jpeg'
      },
      {
        title: 'Gallery.8.title',
        largeImage: 'img/gallery/Gallery09.jpg',
        smallImage: 'img/gallery/Gallery09.jpg'
      },
      {
        title: 'Gallery.9.title',
        largeImage: 'img/gallery/Gallery10.jpg',
        smallImage: 'img/gallery/Gallery10.jpg'
      },
      {
        title: 'Gallery.10.title',
        largeImage: 'img/gallery/Gallery11.jpg',
        smallImage: 'img/gallery/Gallery11.jpg'
      },
      {
        title: 'Gallery.11.title',
        largeImage: 'img/gallery/Gallery12.jpg',
        smallImage: 'img/gallery/Gallery12.jpg'
      },
      {
        title: 'Gallery.12.title',
        largeImage: 'img/gallery/Gallery13.jpg',
        smallImage: 'img/gallery/Gallery13.jpg'
      },
      {
        title: 'Gallery.13.title',
        largeImage: 'img/gallery/Gallery14.jpg',
        smallImage: 'img/gallery/Gallery14.jpg'
      },
      {
        title: 'Gallery.14.title',
        largeImage: 'img/gallery/Gallery15.jpg',
        smallImage: 'img/gallery/Gallery15.jpg'
      },
      {
        title: 'Gallery.15.title',
        largeImage: 'img/gallery/Gallery16.jpg',
        smallImage: 'img/gallery/Gallery16.jpg'
      },
    ],
    Services: [
      {
        icon: 'fa fa-wordpress',
        name: 'Services.0.name',
        text: 'Services.0.text'
      },
      {
        icon: 'fa fa-cart-arrow-down',
        name: 'Services.1.name',
        text: 'Services.1.text'
      },
      {
        icon: 'fa fa-cloud-download',
        name: 'Services.2.name',
        text: 'Services.2.text'
      },
      {
        icon: 'fa fa-language',
        name: 'Services.3.name',
        text: 'Services.3.text'
      },
      {
        icon: 'fa fa-plane',
        name: 'Services.4.name',
        text: 'Services.4.text'
      },
      {
        icon: 'fa fa-pie-chart',
        name: 'Services.5.name',
        text: 'Services.5.text'
      }
    ],
    Contact: {
      img: 'img/contact/1.png',
      address: 'Contact.address',
      phone: 'Contact.phone',
      email: 'Contact.email',
      facebook: 'Contact.facebook',
      instagram: 'Contact.instagram',
      icon: 'fa fa-phone',
      icon1: 'fa fa-facebook',
      icon2: 'fa fa-instagram',
      icon3: 'fa fa-map-marker',
      icon4: 'fa fa-envelope'
    },
    Features: [
      {
        h3: 'Features.0.h3',
        title: 'Features.0.title',
        text: 'Features.0.text',
        text1: 'Features.0.text1'
      },
      {
        h3: 'Features.1.h3',
        title: 'Features.1.title',
        text: 'Features.1.text',
        text1: 'Features.1.text1'
      }
    ]
  };
  

  return (
    <div>
      <Navigation />
      {/* <UnderConstruction /> */}
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      {/* <Services data={landingPageData.Services} /> */}
      <Gallery data={landingPageData.Gallery} />
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
