import React from "react";
import { useTranslation } from "react-i18next";

export const Features = (props) => {
  const { t } = useTranslation()

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{t("tentangkami")}</h2>
          <p>
            <b>SALMONSEGAR.ID</b> {t("tentangkamidesc")}
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6">
                  <div className="features">
                    <div className="features-text">
                      {" "}
                      <div className="features-head-text">{t(d.h3)}</div>
                    </div>
                    <div className="features-content">{t(d.text)}</div>
                    <div className="features-title">
                      {" "}
                      <h3 className="tittle">
                        <b>{t(d.title)}</b>
                      </h3>
                    </div>
                    <div className="features-content">{t(d.text1)}</div>
                  </div>
                </div>

                // <div key={`${d.title}-${i}`} className="col-xs-6" style={{ width: "50%" }}>
                //   {" "}
                //   <i className={d.img}></i>
                //   <h3>{d.title}</h3>
                //   <p>{d.text}</p>
                // </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
