import React, { useEffect, useState } from "react";
import useWindowSize from "../useWindowSize";
import { useTranslation } from "react-i18next";

export default function ProdukModal({ showModal, setShowModal }) {
  const { data } = showModal || {};
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const [selectedImg, setSelectedImg] = useState(1)

  useEffect(() => {
    // Target the first <body> element in the collection
    const body = document.getElementsByTagName("body")[0];

    // Disable scrolling
    body.style.overflow = "hidden";

    // Re-enable scrolling when the component is unmounted
    return () => {
      body.style.overflow = "auto";
    };
  }, []);

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <div style={{ marginBottom: "10px" }}>
            <span
              className="close"
              onClick={() => setShowModal({ val: false, data: null })}
            >
              &times;
            </span>
            <div className="modal-title">{t(data.title)}</div>
            <div className="modal-subtitle">{t(data.description)}</div>
        </div>
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${width > 720 ? "2" : "1"}, minmax(0, 1fr))`,
              gridTemplateRows: `repeat(${width > 720 ? "1" : "2"}, 1fr)`,
              gap: "10px",
              height: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: "8px",
                    boxShadow:
                      "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                  }}
                  src={`img/produk/${data.folder}/${selectedImg}.jpg`}
                />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                overflow: "auto",
                height: "100%",
                gap: "10px",
              }}
            >
              {data &&
                Array(+data.totalFiles)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <img
                        style={{
                          width: "100%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                          borderRadius: "8px",
                          boxShadow:
                            "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                            cursor: "pointer",
  
                        }}
                        onClick={() => setSelectedImg(index + 1)}
                        src={`img/produk/${data.folder}/${index + 1}.jpg`}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
