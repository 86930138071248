import React, { useEffect, useRef, useState } from "react";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import GalleryImage1 from "../assets/gallery/Gallery01.jpg";
import GalleryImage2 from "../assets/gallery/Gallery02.jpg";
import GalleryImage3 from "../assets/gallery/Gallery03.jpeg";
import { useTranslation } from "react-i18next";

const arrowButtonCSS = {
  display: "block",
  opacity: 0.8,
  color: "white",
  fontWeight: "bold",
  position: "absolute",
  bottom: "20%",
  zIndex: "3",
  marginRight: "80px",
  transform: "translate(-50%, 0)",
  cursor: "pointer",
};

export const Header = (props) => {
  const { t } = useTranslation();
  const [imgSlider, setImgSlider] = useState(null);
  const [textSlider, setTextSlider] = useState(null);

  let imgRef = useRef(null);
  let textRef = useRef(null);

  useEffect(() => {
    setImgSlider(imgRef);
    setTextSlider(textRef);
  }, []);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <BsArrowLeftCircle
        // className={className}
        size="3rem"
        style={{ ...arrowButtonCSS, left: "calc(50% - 40px)" }}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <BsArrowRightCircle
        // className={className}
        size="3rem"
        style={{ ...arrowButtonCSS, left: "calc(50% + 40px)" }}
        onClick={onClick}
      />
    );
  };

  const imgSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 1500,
    arrows: true,
  };

  const textSettings = {
    dots: false,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1500,
    arrows: false,
  };

  const SliderContent = [
    {
      image: GalleryImage1,
      mainText: "PREMIUM QUALITY",
      subText: "SEAFOOD",
    },
    {
      image: GalleryImage2,
      mainText: "PREMIUM QUALITY",
      subText: "SEAFOOD",
    },
    {
      image: GalleryImage3,
      mainText: "PREMIUM QUALITY",
      subText: "SEAFOOD",
    },
    // {
    //   image: GalleryImage4,
    //   mainText: "Tes Textaaa",
    //   subText: "Sub Textasds",
    // },
    // {
    //   image: GalleryImage5,
    //   mainText: "Tes Textfff",
    //   subText: "Sub Textgggg",
    // },
  ];

  return (
    <header id="header" className="container">
      <div className="outer-slider">
        <div className="slider-container">
          <Slider
            asNavFor={textSlider}
            ref={(slider) => (imgRef = slider)}
            {...imgSettings}
          >
            {SliderContent.map((value, index) => {
              return (
                <div className="background">
                  <img
                    src={value.image}
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      filter: "brightness(0.8)",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      zIndex: "1",
                      background:
                        "linear-gradient(to right, rgba(0, 0, 0, 0.6) 30%, transparent 70%)",
                    }}
                  />
                </div>
              );
            })}
          </Slider>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              width: "768px",
              maxWidth: "100%",
              height: "100%",
              textOverflow: "clip",
            }}
          >
            <Slider
              asNavFor={imgSlider}
              ref={(slider) => (textRef = slider)}
              {...textSettings}
            >
              {SliderContent.map((value, index) => {
                return (
                  <div className="background text-slider">
                    <div className="mainText">{value.mainText}</div>
                    <div className="subText">{value.subText}</div>
                    <a
                      
                      className="order-btn"
                      href="https://api.whatsapp.com/send/?phone=%2B6285817935433&text&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      <i
                        style={{ fontSize: "3rem" }}
                        className="fa fa-whatsapp"
                      />
                      {t("order")}
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </header>
  );
};

{
  /* <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */
}
